import { Component, EventEmitter, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import { FileValidator } from 'ngx-material-file-input';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OpportunityDetailsComponent } from 'src/app/modules/admin/opportunity-management/opportunity-details/opportunity-details.component';
import { ProfileDetailComponent } from 'src/app/modules/admin/profile-management/profile-detail/profile-detail.component';
import { SaleAccountDetailsComponent } from 'src/app/modules/admin/sale-account-management/sale-account-details/sale-account-details.component';
import { SaleLeadDetailsComponent } from 'src/app/modules/admin/sale-lead-management/sale-lead-details/sale-lead-details.component';
import { environment } from 'src/environments/environment';
import { PagedData } from '../../models/paging/paged-data';
import { DateFormatPipe } from '../../pipes/date-time-format.pipe';
import { Helper } from '../../utility/Helper';
import { AdvancedImportDataComponent } from '../advanced-import-data/advanced-import-data.component';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { PrimasTableComponent } from '../template-management/primas-table/primas-table.component';
import * as XLSX from 'xlsx';
import { ProfileService } from 'src/app/modules/admin/profile-management/profile.service';
import { NbToastrService } from '@nebular/theme';
import { ActivatedRoute } from '@angular/router';
import { CreateEntryContactComponent } from './create-entry-contact/create-entry-contact.component';
import { permissionContactTab } from '../../contances/permission';
import { SaleLeadService } from 'src/app/modules/admin/sale-lead-management/sale-lead.service';
import { ConvertSaleLeadInputModel } from 'src/app/modules/admin/sale-lead-management/convert-sale-lead/convert-sale-lead.model';
import { ConvertSaleLeadNotificationComponent } from 'src/app/modules/admin/sale-lead-management/convert-sale-lead/convert-sale-lead-notification/convert-sale-lead-notification.component';
import { SaleAccountService } from 'src/app/modules/admin/sale-account-management/sale-account.service';
import { OpportunityManagementService } from 'src/app/modules/admin/opportunity-management/opportunity-management.service';
import { TblActionType } from '../../enums/tbl-action-type.enum';
import { AdminTabModeService } from '../stand-alone-component/admin-tab-mode/admin-tab-mode.service';
import { ImportHelperComponent } from './import-helper/import-helper.component';
import { FilterMapping } from '../../models/paging/filter-mapping';
import { PrimasFilterType } from '../../enums/primas-value-type.enum';
import { PrimasAllFilterOperator } from '../../enums/primas-all-filter-operator';
import { PrimasTextFilterOperator } from '../../enums/primas-text-filter-operator';
import { ContactService } from '../../services/contact.service';
import { TabTypeMode, TabTypeModeEnums } from '../stand-alone-component/admin-tab-mode/tab-mode.model';
import { ContactReviewComponent } from './contact-review/contact-review.component';
import { DropDownValue } from 'src/app/modules/admin/summary-report/summary-report.component';

@Component({
  selector: 'app-advanced-import-contact',
  templateUrl: './advanced-import-contact.component.html',
  styleUrls: ['./advanced-import-contact.component.scss']
})
export class AdvancedImportContactComponent implements OnInit {

  @ViewChild('importRequired', { static: true }) importRequired: TemplateRef<any>;
  @ViewChild('headerFromFile', { static: true }) headerFromFile: TemplateRef<any>;
  @ViewChild('reviewInfo', { static: true }) reviewInfo: TemplateRef<any>;
  @ViewChild('primasTable', { static: true }) primasTable: PrimasTableComponent;
  @ViewChild('stepper') private stepper: MatStepper;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('valueTemplate', { static: true }) value: TemplateRef<any>;
  @ViewChild('externalTemplate', { static: true }) externalTemplate: TemplateRef<any>;
  @ViewChild('reviewContactAction', { static: true }) reviewContactAction: TemplateRef<any>;


  bulkActionFunction = new EventEmitter<any>();
  readonly maxSize = environment.maxSizeUpload;
  readonly environment = environment;

  isLoading = false;
  formDoc: FormGroup;
  isComplete = false;
  columns = [];
  systemProperty;
  templateFile: string;
  rows = new Array<any>();
  columnsFile = new Array<any>();
  ColumnMode = ColumnMode;
  onImport = new EventEmitter();
  reviewColumns = [];
  totalRow: number;
  totalSuccess: number;
  totalError: number;
  fileError: any = null;
  systemError = false;
  totalUpdate: number = 0;
  totalNew: number = 0;
  totalReview: number = 0;
  reviewList = [];
  paramsQuery;
  primaryKey: string = 'profileId'; //default primary key on the system
  private readonly destroy$ = new Subject<void>();
  resource = permissionContactTab.table;
  objectReference: any;
  tabMode: string = '';
  tabModeLst: DropDownValue[] = [
    { value: "LEADS", text: environment.titleLead },
    { value: "SALEACCOUNT", text: environment.titleAccount },
    { value: "OPPORTUNITY", text: environment.titleOpportunity },
    { value: "", text: "Contacts" }]


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private toast: NbToastrService,
    private profileService: ProfileService,
    private frmBuilder: FormBuilder,
    public dialModalRef: MatDialogRef<AdvancedImportContactComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DateFormatPipe,
    private saleLeadService: SaleLeadService,
    private accountService: SaleAccountService,
    private opportunityService: OpportunityManagementService,
    private tabService: AdminTabModeService,
    private contactService: ContactService,
  ) {
    this.getConfigFromRoute();
    this.setupContactColumn();
    this.setupData();
  }

  ngOnInit() {
    this.columns = [
      {
        name: 'System property',
        prop: 'systemPropertyName',
        cellTemplate: this.importRequired,
        resizeable: true,
        canAutoResize: true,
      },
      {
        name: 'Column Header From File',
        prop: 'columnFile',
        cellTemplate: this.headerFromFile,
        resizeable: true,
        canAutoResize: true,
      },
      {
        name: 'Preview Info',
        prop: 'reviewInfo',
        cellTemplate: this.reviewInfo,
        resizeable: true,
        canAutoResize: true,
      },

    ];
    /// this.systemProperty = this.data?.systemProperty;
    this.templateFile = this.data?.templateFile;
    if (this.systemProperty && this.systemProperty.length > 0) {
      this.systemProperty.forEach((x: any) => {
        this.rows.push({
          "systemPropertyName": x.name,
          "systemProperty": x.prop,
          "importRequired": x.importRequired,
          "columnFile": - 1,
          "columnHeader": null,
          "reviewInfo": null
        })
      })
    }
    //this.dialModalRef.updatePosition({ right: '0' });
    //this.dialModalRef.updateSize('1200px', '100%')
    this.formDoc = this.frmBuilder.group({
      requiredfile: [
        undefined,
        [Validators.required, FileValidator.maxContentSize(this.maxSize)]
      ]
    });
  }
  closeDialog() {
    this.dialModalRef.close();
  }
  onStepChange(event) {
    if (event.selectedIndex == 1) {
      this.totalRow = 0;
      this.totalSuccess = 0;
      this.totalError = 0;
      this.fileError = null;
      this.systemError = false;
      this.isComplete = false;
      this.columnsFile = [];
      this.rows.forEach(x => {
        x.columnFile = -1;
        x.reviewInfo = null;
      });
      var reader: FileReader = new FileReader();
      reader.readAsBinaryString(this.formDoc.controls["requiredfile"].value.files[0]);
      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary', sheetRows: 2 });

        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        // ws['!fullref'] = "A1:A3"
        /* save data */

        if (ws) {
          for (var i in ws) {
            if (ws[i].w && ws[i].v) {
              if (Helper.isDateString(ws[i].w)) {
                ws[i].v = ws[i].w;
              }
            }
          }
        }
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 }); // to get 2d array pass 2nd parameter as object {header: 1}
        if (data != null && data.length > 0) {
          let tempColumn = data[0] as [];
          let tempDataColumn = data[1] as [];
          for (let i = 0; i < tempColumn.length; i++) {
            let tempData: any = {
              value: i + 1,
              name: tempColumn[i],
              data: tempDataColumn != null ? tempDataColumn[i] : null,
            }
            // 2022-09-07 tienlm mod start: Improve import mapping
            let formattedName = tempData.name.replace(' ', '').toLowerCase();
            //fill data
            let row = this.rows.find(x =>
              x.systemPropertyName.replace(' ', '').toLowerCase() == formattedName ||
              x.systemPropertyName.toLowerCase() == formattedName ||
              x.systemProperty.toLowerCase() == formattedName
            );
            // 2022-09-07 tienlm mod end
            if (row != null) {
              tempData.selected = true;
              row.columnFile = tempData.value;
              row.columnHeader = tempData.name;
              row.reviewInfo = tempData.data;
              this.rows = [...this.rows];
            }
            setTimeout(() => {
              this.table.recalculate();
              this.table['cd'].markForCheck();
              document.body.style.width = 'auto';
            }, 0);
            this.columnsFile.push(tempData);
          }
        } else {
          //revent file
        }
      };
      setTimeout(() => {
        this.table.recalculate();
        this.table['cd'].markForCheck();
        document.body.style.width = 'auto';
      }, 0);
    }
  }
  finishImport(importResult) {
    this.isComplete = true;
    this.stepper.selected.completed = true;
    this.isLoading = !this.isLoading;
    this.stepper.next();

    if (importResult) {
      this.primasTable.isLoading = 1;
      this.totalError = importResult.totalError;
      this.totalRow = importResult.totalRow;
      this.totalSuccess = importResult.totalSuccess;
      this.fileError = importResult.file;
      this.totalNew = importResult.totalNew;
      this.totalUpdate = importResult.totalUpdate;
      this.totalReview = importResult.totalReview;
      this.reviewList = importResult.reviewList ?? [];
      this.getTabMode();
      this.openHelperDialog();

      let extendColumn = {};

      for (var i = 0; i < this.reviewList.length; i++) {
        if (this.reviewList[i].extendData) {
          try {
            const extendData = JSON.parse(this.reviewList[i].extendData);
            if (extendData) {
              this.reviewList[i] = Object.assign({}, this.reviewList[i], extendData)
              extendColumn = Object.assign({}, extendData, extendData)
            }
          }
          catch (ex) {
            console.log(ex);
          }
        }
      }

      const findTabPriority = this.reviewList.find(x => { return x.typeName == 'LEADS' });

      if (findTabPriority) {
        this.tabMode = findTabPriority.typeName
      }
      else {
        this.tabMode = this.reviewList[0].typeName ?? '';
      }

      const pageData = new PagedData();
      pageData.page.size = -1;
      pageData.page.totalElements = this.tabMode
        ? this.reviewList.filter(x => { return x.typeName == this.tabMode }).length
        : this.reviewList.filter(x => { return !x.typeName }).length;
      pageData.page.totalPages = 1;
      pageData.data = this.tabMode
        ? this.reviewList.filter(x => { return x.typeName == this.tabMode })
        : this.reviewList.filter(x => { return !x.typeName });

      setTimeout(() => {
        this.formatColumn(extendColumn);
        this.primasTable.isLoading = 1;
        this.primasTable.setData(pageData)
        this.primasTable.table.recalculate();
        this.primasTable.table['cd'].markForCheck();
        document.body.style.width = 'auto';
      }, 100);
    } else {
      this.systemError = true;
    }

  }
  onChangeColumn(event, oldValue, rowIndex) {
    if (event.value > -1) {
      let column = this.columnsFile.find(x => x.value == event.value);
      if (column) {
        column.selected = true;
        this.rows[rowIndex].reviewInfo = column.data;
        this.rows[rowIndex].columnHeader = column.name;
      }
    } else {
      this.rows[rowIndex].reviewInfo = null;
    }
    if (oldValue > -1) {
      this.columnsFile.find(x => x.value == oldValue).selected = false;
    }
    this.rows[rowIndex].columnFile = event.value;
    this.rows = [...this.rows];
  }

  importFile() {
    this.isLoading = !this.isLoading;
    const formData = new FormData();
    formData.append('file', this.formDoc.controls["requiredfile"].value.files[0]);
    formData.append('mapping', JSON.stringify(this.rows.map(x => ({
      index: x.columnFile,
      prop: x.systemProperty,
      name: x.systemPropertyName,
      columnHeader: x.columnHeader,
      inputValue: x.columnFile == -2 ? x.reviewInfo?.toString() : '',
    }))))
    this.onClickImport(formData)
  }

  downloadFileError() {
    Helper.downloadFile(this.fileError);
  }

  inputProperty(rowIndex: any, value: string) {
    if (rowIndex > -1 && value) this.rows[rowIndex].reviewInfo = value;
    this.rows = [...this.rows];
  }
  formatColumn(row: any) {
    for (const key in row) {
      if (Object.prototype.hasOwnProperty.call(row, key)) {
        if (key == this.primaryKey) continue;
        if (key == 'extendData') continue;
        const column = {
          name: this.formatName(key),
          prop: key,
          cellTemplate: this.value,
          sortable: true,
          filter: true,

        };
        this.reviewColumns.push(column);
      }
    }
    for (var i = 0; i < this.reviewColumns.length; i++) {
      this.reviewColumns[i].cellTemplate = this.value;
    }
    const keyIndex = this.reviewColumns.findIndex(x => { return x.prop.toLowerCase() == this.primaryKey.toLowerCase() });
    if (keyIndex > - 1) this.reviewColumns.splice(keyIndex, 1);

    this.primasTable.columnsTable = this.reviewColumns as []
    this.primasTable.clearAllFilter();
  }

  formatName(name: string) {
    return Helper.displayNameProp(name);
  }
  async onClickBulkAction(reviewLst, action: string) {
    const dialogConfirm = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to ${action.toLowerCase()} ${reviewLst.length} item(s)?`
      }, disableClose: true,
    })

    const result = await dialogConfirm.afterClosed().toPromise();
    if (result) {
      const rs =
        action == 'Add'
          ?
          await this.profileService.saveSaleProcessEntity(reviewLst, 'Contact').toPromise()
          : await this.profileService.saveContact(reviewLst, action).toPromise();
      if (rs.result) {
        this.toast.success("Saved");
      }
    }
  }
  onClickRow(row, action) {
    this.onClickBulkAction([row], action);
  }
  refreshData(reset: boolean) {
    const tabModeData = this.tabMode
      ? this.reviewList.filter(x => { return x.typeName == this.tabMode })
      : this.reviewList.filter(x => { return !x.typeName });

    this.primasTable.isLoading = 1;
    if (reset) {
      this.primasTable.page.pageNumber = 0;
      this.primasTable.cache = {};
      this.primasTable.rows = [];
      this.primasTable.selected = [];
      //this.primasTable.tableAction = false;
      this.primasTable.table.offset = 0;
      this.primasTable.table.bodyComponent._offset = 0;

    }
    this.primasTable.isLoading = 1;
    if (this.primasTable.page.filter) {
      const data = Helper.filterClientSidePaging(this.primasTable.page.filter, tabModeData);
      const pageData = new PagedData();
      pageData.page.size = -1;
      pageData.page.totalElements = data.length;
      pageData.page.totalPages = 1;
      pageData.data = data;
      this.primasTable.setData(pageData);
    }
  }
  bulkAction(action: string) {
    const selectedRows =
      this.primasTable.selected && this.primasTable.selected.length > 0
        ? this.primasTable.selected
        : this.primasTable.table.rows;
    this.onClickBulkAction([...selectedRows], action);
  }
  async skipRecord(skipReviewLst) {
    const dialogConfirm = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to skip ${skipReviewLst.length} item(s)?`
      }, disableClose: true,
    })
    const onClickResult = await dialogConfirm.afterClosed().toPromise();

    if (onClickResult) {
      this.removeRow(skipReviewLst);
    }
  }
  removeRow(skipReviewLst) {
    const rootReviewLst = [...this.primasTable.table.rows];
    if (skipReviewLst) {
      for (var i = 0; i < skipReviewLst.length; i++) {
        const findIdx = rootReviewLst.findIndex(x => { return x == skipReviewLst[i] });
        if (findIdx > -1) rootReviewLst.splice(findIdx, 1);

        const findIdxTotal = this.reviewList.findIndex(x => { return x == skipReviewLst[i] });
        if (findIdxTotal > -1) this.reviewList.splice(findIdxTotal, 1);

      }
      this.primasTable.table.rows = rootReviewLst;
      this.primasTable.page.totalElements = rootReviewLst.length;
      this.primasTable.selected = []; //Should be reset the selected record



    }
  }

  onClickToSkipRecord({ row, rowIndex }) {
    this.skipRecord([row]);
  }
  bulkSkipRecord() {
    const selectedRows =
      this.primasTable.selected && this.primasTable.selected.length > 0
        ? this.primasTable.selected
        : this.primasTable.table.rows;
    this.skipRecord([...selectedRows])
  }
  onClickToOpenDialog(row) {
    let component: any;
    let subUrl;
    switch (row.typeName) {
      case "LEADS":
        subUrl = 'sale-lead';
        component = SaleLeadDetailsComponent;
        break;
      case "SALEACCOUNT":
        subUrl = 'sale-account'
        component = SaleAccountDetailsComponent;
        break;
      case "OPPORTUNITY":
        subUrl = 'opportunity'
        component = OpportunityDetailsComponent;
        break;
      default:
        component = ContactReviewComponent;
        break;
    }

    const dialogImport: MatDialogRef<any, any> = this.dialog.open(component, {
      disableClose: true,
      // height: '100vh',
      width: '1000px',
      panelClass: 'dialog-detail',
      data: {
        importColumn: this.formatKeyPairValue(row),
        row,
        advancedImport: true,
        model: { 'profileId': row[this.primaryKey], topic: 'import-contact' },
        externalTemplate: this.reviewContactAction
      }
    });
    if (dialogImport.componentInstance.onAdvancedImport) {
      dialogImport.componentInstance.onAdvancedImport.pipe(takeUntil(this.destroy$)).subscribe((data) => {
        if (data) {
          switch (data) {
            case "Add":
            case "Update":
              this.onClickRow(row, data);
              break;
            case "Skip":
              this.removeRow([row]);
              break;
          }
        }
      });
    }

  }
  formatKeyPairValue(row) {
    const keyPairLst = []
    for (const key in row) {
      if (Object.prototype.hasOwnProperty.call(row, key)) {
        if (key == this.primaryKey) continue;
        if (key == 'extendData') continue;
        var column = {
          name: this.formatName(key),
          prop: key,
          value: row[key],
          sortable: true,
          filter: true,
        };
        keyPairLst.push(column);
      }
    }

    return keyPairLst;

  }
  setupContactColumn() {
    this.systemProperty = [
      {
        name: 'ContactId',
        prop: 'contactId',
        filter: false,
        import: true,
      },
      {
        name: 'Saluation',
        prop: 'saluation',
        filter: true,
        import: true,
      },
      {
        name: 'First Name',
        prop: 'contactName',
        filter: true,
        import: true,
      },
      {
        name: 'Last Name',
        prop: 'contactLastName',
        filter: true,
        import: true,
      },
      {
        name: 'Email',
        prop: 'contactEmail',
        filter: true,
        import: true,
      },
      {
        name: 'Phone',
        prop: 'contactPhone',
        filter: true,
        import: true,

      },
      {
        name: 'Contact Company',
        prop: 'relationship',
        import: true,
        filter: true,
      },
      {
        name: 'Job Title',
        prop: 'jobTitle',
        filter: true,
        import: true,
      },
      {
        name: 'Secondary Email',
        prop: 'secondaryEmail',
        filter: true,
        import: true,
      },
      {
        name: 'Secondary Phone',
        prop: 'secondaryPhone',
        filter: true,
        import: true
      },
      {
        name: 'Cell Phone',
        prop: 'cellPhone',
        filter: true,
        import: true
      },
      {
        name: 'Website',
        prop: 'website',
        filter: true,
        import: true,
      },
      {
        name: 'LinkedIn',
        prop: 'linkedIn',
        filter: true,
        import: true,
        permissionColumn: 'linked-in-contact-table'
      },
      {
        name: 'Department',
        prop: 'department',
        import: false,
        filter: true,
      },
      {
        name: 'Birthday',
        prop: 'birthDate',
        filter: true,
        import: true,
      },
      {
        name: 'Description',
        prop: 'description',
        filter: true,
        import: true,
      },
      {
        name: 'Facebook',
        prop: 'facebook',
        filter: true,
        import: true,
      },
      {
        name: 'Skype',
        prop: 'skype',
        filter: true,
        import: true,
      },
      {
        name: 'WhatsApp',
        prop: 'whatsApp',
        filter: true,
        import: true,
      },
      {
        name: 'Tiktok',
        prop: 'tiktok',
        filter: true,
        import: true,
      },
      {
        name: 'Address',
        filter: true,
        import: true,
        prop: 'address',
      },
      {
        name: 'Address2',
        prop: 'address2',
        filter: true,
        import: true,
      },
      {
        name: 'City',
        prop: 'city',
        filter: true,
        import: true,
      },
      {
        name: 'State/Province',
        prop: 'state',
        filter: true,
        import: true,
      },
      {
        name: 'Region',
        prop: 'region',
        filter: true,
        import: true,
      },
      {
        name: 'Country',
        import: true,
        prop: 'country',
        filter: true,
      },
      {
        name: 'Zipcode',
        prop: 'zipcode',
        filter: true,
        import: true,
      },
      {
        name: 'Tags',
        prop: 'tags',
        filter: true,
        import: true,
      }
    ];

    this.systemProperty = Helper.getColumnsPermission(this.systemProperty);
    this.reviewColumns = [...this.systemProperty]
  }

  async createSaleProcessEntry(dataLst) {
    const createSaleProcess = this.dialog.open(ConfirmModalComponent, {
      disableClose: true,
      data: {
        message: `Do you wish to create ${dataLst.length} item(s)?`,
      }
    });
    const dialogResult = await createSaleProcess.afterClosed().toPromise();
    if (dialogResult) {
      for (var idx = 0; idx < dataLst.length; idx++) {
        dataLst[idx].profileId = this.paramsQuery.refId;
      }
      const saveEntity = await this.profileService.saveSaleProcessEntity(dataLst, "Contact").toPromise();
      if (saveEntity && saveEntity.result) {
        this.toast.success("Saved");
      }
    }
  }

  createNewContact(data) {
    this.createSaleProcessEntry([data]);
  }



  getConfigFromRoute() {
    this.route.queryParams.subscribe(e => {
      this.paramsQuery = e;
    })
  }

  onClickImport(dataImport): void {
    this.profileService.importContactWithRelationship(dataImport, this.paramsQuery.refId).subscribe(resp => {
      this.finishImport(resp.result);
    });
  }
  setupData() {
    if (this.data.primaryKey) {
      this.primaryKey = this.data.primaryKey
    }
    const keyIndex = this.reviewColumns.findIndex(x => { return x.prop == this.primaryKey });
    if (keyIndex > - 1) this.reviewColumns.splice(keyIndex, 1);

    const keyContactIndex = this.reviewColumns.findIndex(x => { return x.prop == 'contactId' });
    if (keyContactIndex > - 1) this.reviewColumns.splice(keyContactIndex, 1);

    this.reviewColumns.push({
      name: 'Relationship',
      prop: 'typeName',
      filter: false,
      frozenLeft: true,
    })
    if (this.resource) {
      this.resource.edit = 'no_permission';
      this.resource.remove = 'no_permission';
    }
  }

  async convertLead(data) {
    const leadConvert = this.dialog.open(ConfirmModalComponent, {
      disableClose: true,
      data: {
        message: `Do you wish to convert this ${environment.titleLead.toLowerCase()} with option: <b>Existing ${environment.titleAccount}</b> and <b>New Contact</b>?`,
      }
    });
    const confirmResult = await leadConvert.afterClosed().toPromise();
    if (confirmResult) {
      const convertModel = {
        orgLeadId: data.profileId,
        isNewAccount: false,
        isNewContact: true,
        existedSaleAccountId: this.paramsQuery.refId,
      } as ConvertSaleLeadInputModel;
      const result = await this.saleLeadService.convertSaleLead(convertModel).toPromise();
      if (result && result.result > 0) {
        this.toast.success('Convert success', 'Success');
        let successDialog = this.dialog.open(ConvertSaleLeadNotificationComponent, {
          width: '70vw',
          disableClose: true,
          data: {
            convertHistoryId: result.result
          }
        });
      }
    }
  }

  async addToRelationship(data) {
    const leadConvert = this.dialog.open(ConfirmModalComponent, {
      disableClose: true,
      data: {
        message: `Do you wish to add this duplicate ${environment.titleAccount.toLowerCase()} entry to this ${environment.titleAccount.toLowerCase()}'s relationship?`,
      }
    });
    const confirmResult = await leadConvert.afterClosed().toPromise();
    if (confirmResult) {
      const convertModel = {
        orgLeadId: data.profileId,
        isNewAccount: false,
        isNewContact: true,
        existedSaleAccountId: this.paramsQuery.refId,
      } as ConvertSaleLeadInputModel;
      const result = await this.accountService.addEditResellerOrRelationship(this.paramsQuery.refId, data.profileId, 'Relationship', false).toPromise();
      if (result && result.result) {
        this.toast.success(`Successfully added!`, "Success");
      }
    }
  }
  async addMoreOpportunity(data) {

    const confirmDialog = this.dialog.open(ConfirmModalComponent, {
      disableClose: true,
      data: {
        message: `Do you wish to add this ${this.environment.titleOpportunity.toLowerCase()}'s ${this.environment.titleAccount.toLowerCase()} to this ${this.environment.titleAccount.toLowerCase()}'s relationship?`,
      }
    });

    const confirmResult = await confirmDialog.afterClosed().toPromise();

    if (confirmResult) {
      ;
      const result = await this.opportunityService.addAccountOpportunityToRelationship(this.paramsQuery.refId, data.profileId).toPromise();
      if (result && !result.message) {
        if (result.result) {
          this.toast.success(`Successfully added!`, "Success");
        }
        if (!result.referenceObject) {

          const dialogAdd = this.dialog.open(ConfirmModalComponent, {
            disableClose: true,
            data: {
              message: `This ${this.environment.titleOpportunity.toLowerCase()} isn't belong to any ${this.environment.titleAccount.toLowerCase()}. 
              Do you wish to add this ${this.environment.titleOpportunity.toLowerCase()} to this ${this.environment.titleAccount.toLowerCase()} 's ${this.environment.titleOpportunity.toLowerCase()}?`
            }
          });

          const dialogResult = await dialogAdd.afterClosed().toPromise();

          if (dialogResult) {
            const result = await this.opportunityService.addOpportunityToAccount(this.paramsQuery.refId, data.profileId).toPromise();
            if (result && result.result) {
              this.toast.success(`Successfully added!`, "Success");
            }
          }
        }
      }
    }
  }

  openAccount() {
    const dialogRef = this.dialog.open(SaleAccountDetailsComponent, {
      disableClose: true,
      height: '100vh',
      width: '600px',
      panelClass: 'dialog-detail',
      autoFocus: false,
      data: {
        model: this.objectReference,
        action: TblActionType.Edit
      }
    });
  }

  closeTab() {
    const currentTab = this.tabService.getCurrentActiveTab();
    const tabData = this.tabService.tabData.filter(x => x.tabId != currentTab.tabId);
    this.tabService.removeTab(currentTab).subscribe();
    this.tabService.setTabData(tabData);

    this.tabService.CreateTabOtherwiseSetActive(`/configuration/sale-account?id=${this.paramsQuery.refId}`, null, TabTypeModeEnums.Link, true);

  }

  openHelperDialog(isHelper = false) {
    const dialogRef = this.dialog.open(ImportHelperComponent, {
      disableClose: true,
      autoFocus: false,
      data: {
        isHelper: isHelper,
        totalError: this.totalError,
        totalNew: this.totalNew,
        totalReview: this.totalReview,
        totalRow: this.totalRow,
        totalSuccess: this.totalSuccess,
        fileError: this.fileError,
        totalUpdate: this.totalUpdate
      }
    });
  }

  changeModeConfig(data) {
    const tabModeData = this.tabMode
      ? this.reviewList.filter(x => { return x.typeName == this.tabMode })
      : this.reviewList.filter(x => { return !x.typeName });
    const pageData = new PagedData();
    pageData.page.size = -1;
    pageData.page.totalElements = tabModeData.length;
    pageData.page.totalPages = 1;
    pageData.data = tabModeData;


    setTimeout(() => {
      this.primasTable.isLoading = 1;
      this.primasTable.setData(pageData)
      this.primasTable.table.recalculate();
      this.primasTable.table['cd'].markForCheck();
      document.body.style.width = 'auto';
    }, 100);
  }

  async bulkConvertLead() {
    const leadConvert = this.dialog.open(ConfirmModalComponent, {
      disableClose: true,
      data: {
        message: `Do you wish to convert this ${environment.titleLead.toLowerCase()} with option: <b>Existing ${environment.titleAccount}</b> and <b>New Contact</b>?`,
      }
    });
    const confirmResult = await leadConvert.afterClosed().toPromise();
    if (confirmResult) {
      const result = await this.bulkSpecialAction();
      if (result && result.result) {
        this.toast.success(`Convert ${result.result} item(s) successfully`, 'Success');
      }
    }
  }

  async bulkSpecialAction() {
    const Ids = (this.primasTable.selected && this.primasTable.selected.length > 0)
      ? this.primasTable.selected.map(e => { return e.profileId })
      : this.primasTable.rows.map(e => { return e.profileId });
    return await this.contactService.bulkSpecialAction(Ids, this.tabMode, this.paramsQuery.refId).toPromise();

  }

  async bulkAddToRelationship() {
    const numberOfItems = (this.primasTable.selected && this.primasTable.selected.length > 0)
      ? this.primasTable.selected.length : this.primasTable.rows.length
    const leadConvert = this.dialog.open(ConfirmModalComponent, {
      disableClose: true,
      data: {
        message: `Do you wish to add ${numberOfItems} duplicate ${environment.titleAccount.toLowerCase()}(s) to this ${environment.titleAccount.toLowerCase()}'s relationship?`,
      }
    });
    const confirmResult = await leadConvert.afterClosed().toPromise();
    if (confirmResult) {
      //Do action
      const result = await this.bulkSpecialAction();
      if (result && result.result) {
        this.toast.success(`${result.result} item(s) successfully added`, 'Success')
      }
    }
  }
  async bulkAddMoreOpportunity() {
    const numberOfItems = (this.primasTable.selected && this.primasTable.selected.length > 0)
      ? this.primasTable.selected.length : this.primasTable.rows.length
    const confirmDialog = this.dialog.open(ConfirmModalComponent, {
      disableClose: true,
      data: {
        message: `Do you wish to add the ${environment.titleAccount.toLowerCase()} of ${numberOfItems} duplicate ${environment.titleOpportunity.toLowerCase()} entries relationship?`,
      }
    });
    const confirmResult = await confirmDialog.afterClosed().toPromise();
    if (confirmResult) {
      //Do action
      const result = await this.bulkSpecialAction();
      if (result && result.result) {
        this.toast.success(`${result.result} item(s) successfully added`, 'Success')
      }
    }
  }
  getTabMode() {
    const data = this.reviewList.map(e => { return e.typeName });
    const unique = [...new Set(data.map(item => item))];
    for (var idx = 0; idx < unique.length; idx++) {
      if (!unique[idx]) unique[idx] = '';
      const tabIndex = this.tabModeLst.findIndex(x => x.value == unique[idx] ?? '');
      if (tabIndex != -1) this.tabModeLst[tabIndex].color = '1';
    }
    this.tabModeLst = this.tabModeLst.filter(x => x.color);
  }
}
