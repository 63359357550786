export const permissionUser = {
    toolbar: {
        toolbar: 'toolbar-user',
        add: 'add-user',
        import: 'import-user',
        export: 'export-user',
        customView: 'custom-view-user',
    },
    table: {
        table: 'table-user',
        edit: 'edit-user',
        remove: 'remove-user',
    }
}

export const permissionProfile = {
    toolbar: {
        toolbar: 'toolbar-profile',
        add: 'add-profile',
        import: 'import-profile',
        export: 'export-profile',
        customView: 'custom-view-profile',
    },
    table: {
        table: 'table-profile',
        edit: 'edit-profile',
        remove: 'remove-profile',
    }
}

export const permissionProducer = {
    toolbar: {
        toolbar: 'toolbar-producer',
        add: 'add-producer',
        import: 'import-producer(turnoff)',
        export: 'export-producer(turnoff)',
        customView: 'custom-view-producer',
    },
    table: {
        table: 'table-producer',
        edit: 'edit-producer',
        remove: 'remove-producer',
    }
}

export const permissionCategory = {
    toolbar: {
        toolbar: 'toolbar-category',
        add: 'add-category',
        import: 'import-category',
        export: 'export-category',
        customView: 'custom-view-category',
    },
    table: {
        table: 'table-category',
        edit: 'edit-category',
        remove: 'remove-category',
    }
}

export const permissionContact = {
    toolbar: {
        toolbar: 'toolbar-contact',
        add: 'add-contact(turnoff)',
        import: 'import-contact',
        export: 'export-contact',
        customView: 'custom-view-contact',
    },
    table: {
        table: 'table-contact',
        edit: 'edit-contact',
        remove: 'remove-contact',
    }
}

export const permissionEnquiry = {
    toolbar: {
        toolbar: 'toolbar-enquiry',
        add: 'add-enquiry',
        import: 'import-enquiry(turnoff)',
        export: 'export-enquiry(turnoff)',
        customView: 'custom-view-enquiry',
    },
    table: {
        table: 'table-enquiry',
        edit: 'edit-enquiry',
    },
    assigen: "assigen-agents",
}
export const permissionBooking = {
    toolbar: {
        toolbar: 'toolbar-booking',
        customView: 'custom-view-booking',
    },
    table: {
        table: 'table-booking',
    }
};

export const permissionCampaign = {
    toolbar: {
        toolbar: 'toolbar-campaign',
        add: 'add-campaign',
        import: 'import-campaign',
        export: 'export-campaign',
        customView: 'custom-view-campaign',
    },
    table: {
        table: 'table-campaign',
        edit: 'edit-campaign',
        remove: 'remove-campaign',
    }
};

export const permissionActivityLog = {
    toolbar: {
        toolbar: 'toolbar-activity-log',
        add: 'add-activity-log(turnoff)',
        import: 'import-activity-log(turnoff)',
        export: 'export-activity-log(turnoff)',
        customView: 'custom-view-activity-log',
    },
    table: {
        table: 'table-activity-log',
        edit: 'edit-activity-log(turnoff)',
        remove: 'remove-activity-log',
    }
};

export const permissionSumaryReport = {
    toolbar: {
        toolbar: 'toolbar-summary-report',
        add: 'add-summary-report',
        import: 'import-summary-report',
        export: 'export-summary-report',
        customView: 'custom-view-summary-report',
    },
    table: {
        table: 'table-summary-report',
        edit: 'edit-summary-report',
        remove: 'remove-summary-report',
    }
}

export const permissionPermissionTable = {
    toolbar: {
        toolbar: 'toolbar-permission',
        add: 'add-permission',
        import: 'import-permission(turnoff)',
        export: 'export-permission(turnoff)',
        customView: 'custom-view-permission',
    },
    table: {
        table: 'table-permission',
        edit: 'edit-permission',
        remove: 'remove-permission',
    }
}
export const permissionRole = {
    toolbar: {
        toolbar: 'toolbar-role',
        add: 'add-role',
        import: 'import-role(turnoff)',
        export: 'import-role(turnoff)',
        customView: 'custom-view-role',
    },
    table: {
        table: 'table-role',
        edit: 'edit-role',
        remove: 'remove-role',
    }
}
export const permissionDiagnosticHistory = {
    toolbar: {
        toolbar: 'toolbar-diagnostic-history',
        import: 'import-diagnostic-history(turnoff)',
        export: 'export-diagnostic-history(turnoff)',
        customView: 'custom-view-diagnostic-history',
    },
    table: {
        table: 'table-diagnostic-history'
    }

}
export const permissionChangelog = {
    toolbar: {
        toolbar: 'toolbar-changelog',
        add: 'add-changelog',
        import: 'import-changelog(turnoff)',
        export: 'export-changelog(turnoff)',
        customView: 'custom-view-changelog',
    },
    table: {
        table: 'table-changelog',
        edit: 'edit-changelog',
        remove: 'remove-changelog',
    }
}

export const permissionTaskGrid = {
    toolbar: {
        toolbar: 'toolbar-task',
        add: 'add-task',
        import: 'import-task(turnoff)',
        export: 'export-task(turnoff)',
        customView: 'custom-view-task',
    },
    table: {
        table: 'table-task',
        edit: 'edit-task',
        remove: 'remove-task',
    }
}

//2022-03-21 HMTien add start
export const permissionOnboardData = {
    toolbar: {
        toolbar: 'toolbar-onboarding-data',
        add: 'add-onboarding-data',
        import: 'import-onboarding-data(turnoff)',
        export: 'export-onboarding-data(turnoff)',
        customView: 'custom-view-onboarding-data(turnoff)',
    },
    table: {
        table: 'table-onboarding-data',
        edit: 'edit-onboarding-data',
        remove: 'remove-onboarding-data',
    }


}
export const permissionSeoInfo = {
    toolbar: {
        toolbar: 'toolbar-seo-info',
        add: 'add-seo-info',
        import: 'import-seo-info(turnoff)',
        export: 'export-task(turnoff)',
        customView: 'custom-view-seo-info(turnoff)',
    },
    table: {
        table: 'table-seo-info',
        edit: 'edit-seo-info',
        remove: 'remove-seo-info',
    }
}
//2022-03-21 HMTien add end
//2022-03-21 HMTien add start
export const permissionAutoAssignmentReport = {
    toolbar: {
        toolbar: 'toolbar-auto-assignment-report',
        add: 'add-auto-assignment-report(turnoff)',
        import: 'import-auto-assignment-report(turnoff)',
        export: 'export-auto-assignment-report(turnoff)',
        customView: 'custom-view-auto-assignment-report(turnoff)',
    },
    table: {
        table: 'table-auto-assignment-report',
        edit: 'edit-auto-assignment-report(turnoff)',
        remove: 'remove-auto-assignment-report(turnoff)',
    }


}

//2022-03-21 HMTien add end
// 10/03/2022 toanpq change
export const permissionTaskscript = {
    toolbar: {
        toolbar: 'toolbar-taskscript-management',
        add: 'add-taskscript-management',
        import: 'import-taskscript-management',
        export: 'import-taskscript-management',
        customView: 'custom-view-taskscript-management',
    },
    table: {
        table: 'table-taskscript-management',
        edit: 'edit-taskscript-management',
        remove: 'remove-taskscript-management',
    }
}
export const permissionTaskscriptGroup = {
    toolbar: {
        toolbar: 'toolbar-taskscript-group-management',
        add: 'add-taskscript-group-management',
        import: 'import-taskscript-group-management(turnoff)',
        export: 'import-taskscript-group-management(turnoff)',
        customView: 'custom-view-taskscript-group-management',
    },
    table: {
        table: 'table-taskscript-management',
        edit: 'edit-taskscript-management',
        remove: 'remove-taskscript-management',
    }
}
/* export const permissionTaskTemplate = {
  toolbar: {
      toolbar: 'toolbar-taskscript-group-management',
      add: 'add-taskscript-group-management',
      import: 'import-taskscript-group-management(turnoff)',
      export:  'import-taskscript-group-management(turnoff)',
      customView: 'custom-view-taskscript-group-management',
  },
  table: {
      table: 'table-taskscript-management',
      edit: 'edit-taskscript-management',
      remove: 'remove-taskscript-management',
  }
} */
//10/03/2022 toanpq change - end
//2022-03-17 toanpq add change start

//2022-03-17 toanpq add change end

// 2022-03-22 tienlm add start
export const permissionTaskPlan = {
    toolbar: {
        toolbar: 'toolbar-task-plan',
        add: 'add-task-plan',
        import: 'import-task-plan(turnoff)',
        export: 'import-task-plan(turnoff)',
        customView: 'custom-view-task-plan',
    },
    table: {
        table: 'table-task-plan',
        edit: 'edit-task-plan',
        remove: 'remove-task-plan',
    }
}
// 2022-03-22 tienlm add end

export const permissionEmailHistory = {
    toolbar: {
        toolbar: 'toolbar-email-history',
        add: 'add-email-history(turnoff)',
        import: 'import-email-history(turnoff)',
        export: 'import-email-history(turnoff)',
        customView: 'custom-view-email-history',
        sendByMe: 'send-by-me-email-history',
    },
    table: {
        table: 'table-email-history',
        edit: 'edit-email-history(turnoff)',
        remove: 'remove-email-history(turnoff)',
    }
}

//2022-04-04 hmtien add start
export const permissionContactType = {
    toolbar: {
        toolbar: 'toolbar-contact-type',
        add: 'add-contact-type',
        import: 'import-contact-type(turnoff)',
        export: 'import-contact-type(turnoff)',
        customView: 'custom-view-contact-type',
    },
    table: {
        table: 'table-contact-type',
        edit: 'edit-contact-type',
        remove: 'remove-contact-type',
    }
}
//2022-04-04 hmtien add end

export const permissionAccessLog = {
    toolbar: {
        toolbar: 'toolbar-access-log',
        add: 'add-access-log',
        import: 'import-access-log(turnoff)',
        export: 'import-access-log(turnoff)',
        customView: 'custom-view-access-log',
    },
    table: {
        table: 'table-access-log',
        edit: 'edit-access-log',
        remove: 'remove-access-log',
    }
}

export const permissionAccessLogTab = {
    toolbar: {
        toolbar: 'toolbar-access-log',
        add: 'add-access-log(turnoff)',
        import: 'import-access-log(turnoff)',
        export: 'import-access-log(turnoff)',
        customView: 'custom-view-access-log(turnoff)',
    },
    table: {
        table: 'table-access-log',
        edit: 'edit-access-log',
        remove: 'remove-access-log',
    }
}

export const permissionAccessExclude = {
    toolbar: {
        toolbar: 'toolbar-access-log',
        add: 'add-access-log',
        import: 'import-access-log(turnoff)',
        export: 'import-access-log(turnoff)',
        customView: 'custom-view-access-log(turnoff)',
    },
    table: {
        table: 'table-access-log',
        edit: 'edit-access-log',
        remove: 'remove-access-log',
    }
}
//2022-03-21 HMTien add start
export const permissionMedia = {
    toolbar: {
        toolbar: 'toolbar-media',
        add: 'add-media',
        import: 'import-media(turnoff)',
        export: 'export-media(turnoff)',
        customView: 'custom-view-media',
    },
    table: {
        table: 'table-media',
        edit: 'edit-media',
        remove: 'remove-media',
    }
}

//2022-03-21 HMTien add end

export const permissionDataField = {
    toolbar: {
        toolbar: 'toolbar-data-field',
        add: 'add-data-field',
        import: 'import-data-field(turnoff)',
        export: 'export-data-field(turnoff)',
        customView: 'custom-view-data-field(turnoff)',
    },
    table: {
        table: 'table-data-field',
        edit: 'edit-data-field',
        remove: 'remove-data-field',
    }
}
//2022-04-28 thoai add start
export let permissionCallEvent = {
    toolbar: {
        toolbar: 'toolbar-call-event',
        add: 'add-call-event',
        import: 'import-call-event(turnoff)',
        export: 'export-call-event(turnoff)',
        customView: 'custom-view-call-event(turnoff)',
    },
    table: {
        table: 'table-call-event',
        edit: 'edit-call-event',
        remove: 'remove-call-event',
    }
}
//2022-04-28 thoai add end
export const permissionSystemLog = {
    toolbar: {
        toolbar: 'toolbar-data-field(turnoff)',
        add: 'add-data-field(turnoff)',
        import: 'import-data-field(turnoff)',
        export: 'export-data-field(turnoff)',
        customView: 'custom-view-data-field(turnoff)',
    },
    table: {
        table: 'table-data-field',
        edit: 'edit-data-field(turnoff)',
        remove: 'remove-data-field(turnoff)',
    }
}
export const permissionBuyer = {
    toolbar: {
        toolbar: 'toolbar-profile',
        add: 'add-profile',
        import: 'import-profile',
        export: 'export-profile',
        customView: 'custom-view-profile',
    },
    table: {
        table: 'table-profile',
        edit: 'edit-profile',
        remove: 'remove-profile',
    }
}
export const permissionBuyerType = {
    toolbar: {
        toolbar: 'toolbar-buyer-type',
        add: 'add-buyer-type',
        import: 'import-buyer-type(turnoff)',
        export: 'export-buyer-type(turnoff)',
        customView: 'custom-view-buyer-type',
    },
    table: {
        table: 'table-buyer-type',
        edit: 'edit-buyer-type',
        remove: 'remove-buyer-type',
    }
}
export const permissionSupportTicket = {
    toolbar: {
        toolbar: 'toolbar-support-ticket',
        add: 'add-support-ticket',
        import: 'import-support-ticket',
        export: 'export-support-ticket',
        customView: 'custom-view-support-ticket',
    },
    table: {
        table: 'table-support-ticket',
        edit: 'edit-support-ticket',
        remove: 'remove-support-ticket(turnoff)',
    }
}

export const permissionAdminQueries = {
    toolbar: {
        toolbar: 'configuration-admin-queries',
        add: 'configuration-admin-queries(turnoff)',
        import: 'configuration-admin-queries(turnoff)',
        export: 'configuration-admin-queries',
        customView: 'configuration-admin-queries(turnoff)',
    },
    table: {
        table: 'configuration-admin-queries',
        edit: 'configuration-admin-queries',
        remove: 'configuration-admin-queries',
    }
}

export const permissionQueryHistory = {
    toolbar: {
        toolbar: 'toolbar-query-history',
        add: 'add-query-history(turnoff)',
        import: 'import-query-history(turnoff)',
        export: 'export-query-history(turnoff)',
        customView: 'custom-view-query-historyy',
    },
    table: {
        table: 'table-query-history',
        edit: 'edit-query-history(turnoff)',
        remove: 'remove-query-history(turnoff)',
    }
}
export const permissionBackgroundJob = {
    toolbar: {
        toolbar: 'toolbar-background-job',
        add: 'add-background-job',
        import: 'import-background-job(turnoff)',
        export: 'export-background-job(turnoff)',
        customView: 'custom-view-background-job(turnoff)',
    },
    table: {
        table: 'table-background-job',
        edit: 'edit-background-job',
        remove: 'remove-background-job',
    }
}

//2022-09-06 thoainda add start
export const permissionDataState = {
    toolbar: {
        toolbar: 'toolbar-data-state',
        add: 'add-data-state',
        import: 'import-data-state(turnoff)',
        export: 'export-data-state(turnoff)',
        customView: 'custom-view-data-state',
    },
    table: {
        table: 'table-data-state',
        edit: 'edit-data-state',
        remove: 'remove-data-state',
    }
}
export const permissionPriority = {
    toolbar: {
        toolbar: 'toolbar-priority',
        add: 'add-priority',
        import: 'import-priority(turnoff)',
        export: 'export-priority(turnoff)',
        customView: 'custom-view-priority',
    },
    table: {
        table: 'table-priority',
        edit: 'edit-priority',
        remove: 'remove-priority',
    }
}
export const permissionTaskType = {
    toolbar: {
        toolbar: 'toolbar-task-type',
        add: 'add-producer',
        import: 'import-task-type(turnoff)',
        export: 'export-task-type(turnoff)',
        customView: 'custom-view-task-type',
    },
    table: {
        table: 'table-task-type',
        edit: 'edit-task-type',
        remove: 'remove-task-type',
    }
}
export const permissionTaskStatus = {
    toolbar: {
        toolbar: 'toolbar-task-status',
        add: 'add-producer',
        import: 'import-task-status(turnoff)',
        export: 'export-task-status(turnoff)',
        customView: 'custom-view-task-status',
    },
    table: {
        table: 'table-task-status',
        edit: 'edit-task-status',
        remove: 'remove-task-status',
    }
}
export const permissionResolutionCode = {
    toolbar: {
        toolbar: 'toolbar-resolution-code',
        add: 'add-resolution-code',
        import: 'import-resolution-code(turnoff)',
        export: 'export-resolution-code(turnoff)',
        customView: 'custom-view-resolution-code',
    },
    table: {
        table: 'table-resolution-code',
        edit: 'edit-resolution-code',
        remove: 'remove-resolution-code',
    }
}
//2022-09-06 thoainda add end
export const permissionEmailInbox = {
    toolbar: {
        toolbar: 'toolbar-email-inbox',
        add: 'add-email-inbox(turnoff)',
        import: 'import-email-inbox(turnoff)',
        export: 'export-email-inbox(turnoff)',
        customView: 'custom-view-email-inbox(turnoff)',
        sendByMe: 'send-to-me-email-inbox',
    },
    table: {
        table: 'table-email-inbox',
        edit: 'edit-email-inbox(turnoff)',
        remove: 'remove-email-inbox(turnoff)',
    }
};
export const permissionDatastateChain = {
    toolbar: {
        toolbar: 'toolbar-datastate-chain-management',
        add: 'add-datastate-chain-management',
        import: 'import-datastate-chain-management(turnoff)',
        export: 'import-datastate-chain-management(turnoff)',
        customView: 'custom-view-datastate-chain-management',
    },
    table: {
        table: 'table-datastate-chain-management',
        edit: 'edit-datastate-chain-management',
        remove: 'remove-datastate-chain-management',
    }
}
export const permissionDatastateDataChain = {
    toolbar: {
        toolbar: 'toolbar-datastate-datachain-management',
        add: 'add-datastate-datachain-management',
        import: 'import-datastate-datachain-management(turnoff)',
        export: 'import-datastate-datachain-management(turnoff)',
        customView: 'custom-view-datastate-datachain-management',
    },
    table: {
        table: 'table-datastate-datachain-management',
        edit: 'edit-datastate-datachain-management',
        remove: 'remove-datastate-datachain-management',
    }
}
export const permissionAutomateDataState = {
    toolbar: {
        toolbar: 'toolbar-automate-datastate-management',
        add: 'add-automate-datastate-management',
        import: 'import-automate-datastate-management(turnoff)',
        export: 'import-automate-datastate-management(turnoff)',
        customView: 'custom-view-automate-datastate-management',
    },
    table: {
        table: 'table-automate-datastate-management',
        edit: 'edit-automate-datastate-management',
        remove: 'remove-automate-datastate-management',
    }
}

export const permissionEmailQueue = {
    toolbar: {
        toolbar: 'toolbar-email-queue',
        add: 'add-email-queue(turnoff)',
        import: 'import-email-queue(turnoff)',
        export: 'import-email-queue(turnoff)',
        customView: 'custom-view-email-queue(turnoff)',
    },
    table: {
        table: 'table-email-queue',
        edit: 'edit-email-queue(turnoff)',
        remove: 'remove-email-queue',
    }
}

export const permissionEmailProgress = {
    toolbar: {
        toolbar: 'toolbar-email-progress',
        add: 'add-email-progress(turnoff)',
        import: 'import-email-progress(turnoff)',
        export: 'import-email-progress(turnoff)',
        customView: 'custom-view-email-progress(turnoff)',
    },
    table: {
        table: 'table-email-progress',
        edit: 'edit-email-progress(turnoff)',
        remove: 'remove-email-progress',
    }
}
// WP permission
export const permissionFFCHomePage = {
    toolbar: {
        toolbar: 'toolbar-wp-homepage',
        add: 'add-wp-homepage',
        import: 'import-wp-homepage(turnoff)',
        export: 'import-wp-homepage(turnoff)',
        customView: 'custom-wp-homepage(turnoff)',
    },
    table: {
        table: 'table-wp-homepage',
        edit: 'edit-wp-homepage',
        remove: 'remove-wp-homepage',
    }
}
export const permissionFileSyncProgress = {
    toolbar: {
        toolbar: 'toolbar-file-sync-progress',
        add: 'add-file-sync-progress',
        import: 'import-file-sync-progress(turnoff)',
        export: 'export-file-sync-progress(turnoff)',
        customView: 'custom-file-sync-progress',
    },
    table: {
        table: 'table-file-sync-progress',
        edit: 'edit-file-sync-progress',
        remove: 'remove-file-sync-progress'
    }
}

export const permissionMediaResize = {
    toolbar: {
        toolbar: 'toolbar-media-resize',
        add: 'add-media-resize(turnoff)',
        import: 'import-media-resize(turnoff)',
        export: 'export-media-resize(turnoff)',
        customView: 'custom-view-media-resize(turnoff)',
    },
    table: {
        table: 'table-media-resize',
        edit: 'edit-media-resize(turnoff)',
        remove: 'remove-media-resize(turnoff)',
    }
}
export const permissionSystemLogs = {
    toolbar: {
        toolbar: 'toolbar-system-logs',
        add: 'add-system-logs(turnoff)',
        import: 'import-system-logs(turnoff)',
        export: 'export-system-logs(turnoff)',
        customView: 'custom-view-system-logs(turnoff)',
    },
    table: {
        table: 'table-system-logs(turnoff)',
        edit: 'edit-system-logs(turnoff)',
        remove: 'remove-system-logs(turnoff)',
    }
}
export const permissionTraceLog = {
    toolbar: {
        toolbar: 'toolbar-trace-log',
        add: 'add-trace-log(turnoff)',
        import: 'import-trace-log(turnoff)',
        export: 'export-trace-log',
        customView: 'custom-view-trace-log(turnoff)',
    },
    table: {
        table: 'table-trace-log',
        edit: 'edit-trace-log(turnoff)',
        remove: 'remove-trace-log(turnoff)',
    }
}
export const permissionTraceLogError = {
    toolbar: {
        toolbar: 'toolbar-trace-log-error',
        add: 'add-trace-log-error(turnoff)',
        import: 'import-trace-log-error(turnoff)',
        export: 'export-trace-log-error',
        customView: 'custom-view-trace-log-error(turnoff)',
    },
    table: {
        table: 'table-trace-log-error',
        edit: 'edit-trace-log-error(turnoff)',
        remove: 'remove-trace-log-error(turnoff)',
    }
}
export const permissionTemporaryVariable = {
    toolbar: {
        toolbar: 'toolbar-temporary-variable',
        add: 'add-temporary-variable',
        import: 'import-temporary-variable(turnoff)',
        export: 'export-temporary-variable(turnoff)',
        customView: 'custom-view-temporary-variable',
    },
    table: {
        table: 'table-temporary-variable',
        edit: 'edit-temporary-variable',
        remove: 'remove-temporary-variable'
    }
}

export const permissionSeoDashboard = {
    toolbar: {
        toolbar: 'toolbar-dashboard-seo',
        add: 'add-dashboard-seo(turnoff)',
        import: 'import-dashboard-seo(turnoff)',
        export: 'export-dashboard-seo(turnoff)',
        customView: 'custom-view-dashboard-seo(turnoff)',
    },
    table: {
        table: 'table-dashboard-seo',
        edit: 'edit-dashboard-seo',
        remove: 'remove-dashboard-seo',
    }
}
export const permissionEmailAttachment = {
    toolbar: {
        toolbar: 'toolbar-email-attachment',
        add: 'add-email-attachment(turnoff)',
        import: 'import-email-attachment(turnoff)',
        export: 'export-email-attachment(turnoff)',
        customView: 'custom-view-email-attachment(turnoff)',
    },
    table: {
        table: 'table-email-attachment',
        edit: 'edit-email-attachment(turnoff)',
        remove: 'remove-email-attachment(turnoff)',
    }
}

export const permissionTemporaryVariableHistory = {
    toolbar: {
        toolbar: 'toolbar-bio-rewrite',
        add: 'add-bio-rewrite',
        import: 'import-bio-rewrite',
        export: 'export-bio-rewrite',
        customView: 'custom-view-bio-rewrite',
    },
    table: {
        table: 'table-bio-rewrite',
        edit: 'edit-bio-rewrite',
        remove: 'remove-bio-rewrite',
    }
}

export const permissionBioRewrite = {
    toolbar: {
        toolbar: 'toolbar-bio-rewrite',
        add: 'add-bio-rewrite',
        import: 'import-bio-rewrite',
        export: 'export-bio-rewrite',
        customView: 'custom-view-bio-rewrite',
    },
    table: {
        table: 'table-bio-rewrite',
        edit: 'edit-bio-rewrite',
        remove: 'remove-bio-rewrite',
    }
}
export const permissionPageSpeed = {
    toolbar: {
        toolbar: 'toolbar-page-speed',
        add: 'add-page-speed',
        import: 'import-page-speed',
        export: 'export-page-speed',
        customView: 'custom-view-page-speed',
    },
    table: {
        table: 'table-page-speed',
        edit: 'edit-page-speed',
        remove: 'remove-page-speed',
    }
}

export const permissionSearchProfileByLocation = {
    toolbar: {
        toolbar: 'toolbar-search-profile-by-location',
        add: 'add-search-profile-by-location(turnoff)',
        import: 'import-search-profile-by-location(turnoff)',
        export: 'export-search-profile-by-location(turnoff)',
        customView: 'custom-view-search-profile-by-location',
    },
    table: {
        table: 'table-search-profile-by-location',
        edit: 'edit-search-profile-by-location',
        remove: 'remove-search-profile-by-location(turnoff)',
    }
}

export const permissionLocation = {
    toolbar: {
        toolbar: 'toolbar-location',
        add: 'add-location',
        import: 'import-location',
        export: 'export-location',
        customView: 'custom-view-location',
    },
    table: {
        table: 'table-location',
        edit: 'edit-location',
        remove: 'remove-location',
    }
}
export const permissionPhotoProcessQueue = {
    toolbar: {
        toolbar: 'toolbar-photo-process-queue',
        add: 'add-photo-process-queue',
        import: 'import-photo-process-queue',
        export: 'export-photo-process-queue',
        customView: 'custom-view-photo-process-queue',
    },
    table: {
        table: 'table-photo-process-queue',
        edit: 'edit-photo-process-queue',
        remove: 'remove-photo-process-queue',
    }
}

export const permissionReason = {
    toolbar: {
        toolbar: 'toolbar-reason',
        add: 'add-reason',
        import: 'import-reason',
        export: 'export-reason',
        customView: 'custom-view-reason',
    },
    table: {
        table: 'table-reason',
        edit: 'edit-reason',
        remove: 'remove-reason',
    }
}

export const permissionProfileDetailTab = {
    general: 'edit-general-tab-in-profile-screen',
    reason_bio: 'edit-reason&bio-tab-in-profile-screen',
    contacts: 'edit-contacts-tab-in-profile-screen',
    locations: 'edit-locations-tab-in-profile-screen',
    multimedia: 'edit-multimedia-tab-in-profile-screen',
    seo: 'edit-seo-tab-in-profile-screen',
    activities: 'edit-activities-tab-in-profile-screen',
    relationship: 'edit-relationship-tab-in-profile-screen',
    more_info: 'edit-moreinfo-tab-in-profile-screen'
}

export const permissionDetailProfileItem = {
    hotTrend: "hot-trend",
    socialMedia: "social-media",
    socialBlock: "social-block",
    openProfileAndShowSensitive: "open-profile-in-new-tab-and-show-sensitive-data",
    openProfileOnFrontFacing: "open-profile-on-front-facing",
    copyPreviewPageLink: "copy-preview-page",
}

export const permissionCallbackMapping = {
    toolbar: {
        toolbar: 'toolbar-callback-mapping',
        customView: 'custom-view-callback-mapping',
    },
    table: {
        table: 'table-callback-mapping',
    }
}
export const permissionEmailTemplate = {
    toolbar: {
        toolbar: 'toolbar-email-template',
        add: 'add-email-template',
        import: 'import-email-template(turnoff)',
        export: 'export-email-template(turnoff)',
        customView: 'custom-view-email-template(turnoff)',
    },
    table: {
        table: 'table-email-template',
        edit: 'edit-email-template',
        remove: 'remove-email-template(turnoff)',
    }
}
export const permissionEmailTemplateEndUser = {
    toolbar: {
        toolbar: 'toolbar-email-template-end-user',
        add: 'add-email-template-end-user',
        import: 'import-email-template-end-user(turnoff)',
        export: 'export-email-template-end-user(turnoff)',
        customView: 'custom-view-email-template-end-user(turnoff)',
    },
    table: {
        table: 'table-email-template-end-user',
        edit: 'edit-email-template-end-user',
        remove: 'remove-email-template-end-user(turnoff)',
    }
}


export const permissionTemporaryProfile = {
    toolbar: {
        toolbar: 'toolbar-temporary-profile',
        add: 'add-temporary-profile',
        import: 'import-temporary-profile',
        export: 'export-temporary-profile',
        customView: 'custom-temporary-profile',
    },
    table: {
        table: 'table-temporary-profile',
        edit: 'edit-temporary-profile',
        remove: 'remove-temporary-profile',
    }
}

export const permissionArchiveProfile = {
    toolbar: {
        toolbar: 'toolbar-archive-profile',
        add: 'add-archive-profile',
        import: 'import-archive-profile',
        export: 'export-archive-profile',
        customView: 'custom-archive-profile(turnoff)',
    },
    table: {
        table: 'table-archive-profile',
        edit: 'edit-archive-profile',
        remove: 'remove-archive-profile',
    }
}

export const permissionMapViewCategory = {
    toolbar: {
        toolbar: 'toolbar-map-view-category',
        add: 'add-map-view-category',
        import: 'import-map-view-category(turnoff)',
        export: 'export-map-view-category(turnoff)',
        customView: 'custom-view-map-view-category',
    },
    table: {
        table: 'table-map-view-category',
        edit: 'edit-map-view-category',
        remove: 'remove-map-view-category',
    }
}

export const permissionSaleLead = {
    toolbar: {
        toolbar: 'toolbar-sale-lead',
        add: 'add-sale-lead',
        import: 'import-sale-lead',
        export: 'export-sale-lead',
        customView: 'custom-view-sale-lead',
    },
    table: {
        table: 'table-sale-lead',
        edit: 'edit-sale-lead',
        remove: 'remove-sale-lead',
    }
}

export const permissionOpportunity = {
    toolbar: {
        toolbar: 'toolbar-opportunity',
        add: 'add-opportunity',
        import: 'import-opportunity(turnoff)',
        export: 'export-opportunity',
        customView: 'custom-view-opportunity',
    },
    table: {
        table: 'table-opportunity',
        edit: 'edit-opportunity',
        remove: 'remove-opportunity',
    }
}
export const permissionArchiveSaleLead = {
    toolbar: {
        toolbar: 'toolbar-archive-sale-lead',
        add: 'add-archive-sale-lead',
        import: 'import-archive-sale-lead',
        export: 'export-archive-sale-lead',
        customView: 'custom-archive-sale-lead(turnoff)',
    },
    table: {
        table: 'table-archive-sale-lead',
        edit: 'edit-archive-sale-lead',
        remove: 'remove-archive-sale-lead',
    }
}

export const permissionSaleAccount = {
    toolbar: {
        toolbar: 'toolbar-sale-account',
        add: 'add-sale-account',
        import: 'import-sale-account',
        export: 'export-sale-account',
        customView: 'custom-view-sale-account',
    },
    table: {
        table: 'table-sale-account',
        edit: 'edit-sale-account',
        remove: 'remove-sale-account',
    }
}


export const permissionContactTab = {
    toolbar: {
        toolbar: 'toolbar-contact',
        add: 'add-contact(turnoff)',
        import: 'import-contact(turnoff)',
        export: 'export-contact(turnoff)',
        customView: 'custom-view-contact(turnoff)',
    },
    table: {
        table: 'table-contact',
        edit: 'edit-contact',
        remove: 'remove-contact(turnoff)',
    }
}
export const permissionSaleAccountOpportunityTab = {
    toolbar: {
        toolbar: 'toolbar-opportunity',
        add: 'add-opportunity(turnoff)',
        import: 'import-opportunity(turnoff)',
        export: 'export-opportunity(turnoff)',
        customView: 'custom-view-opportunity',
    },
    table: {
        table: 'table-opportunity',
        edit: 'edit-opportunity',
        remove: 'remove-opportunity(turnoff)',
    }
}
export const permissionApiJob = {
    toolbar: {
        toolbar: 'toolbar-api-job',
        add: 'add-api-job',
        import: 'import-api-job(turnoff)',
        export: 'export-api-job(turnoff)',
        customView: 'custom-view-api-job(turnoff)',
    },
    table: {
        table: 'table-api-job',
        edit: 'edit-api-job',
        remove: 'remove-api-job',
    }
}
export const permissionApiDetail = {
    toolbar: {
        toolbar: 'toolbar-api-detail',
        add: 'add-api-detail',
        import: 'import-api-detail(turnoff)',
        export: 'export-api-detail(turnoff)',
        customView: 'custom-view-api-detail(turnoff)',
    },
    table: {
        table: 'table-api-detail',
        edit: 'edit-api-detail',
        remove: 'remove-api-detail',
    }
}


export const permissionGridNoteProfile = {
    table: 'table-note-profile',
    edit: 'edit-note-profile',
    remove: 'remove-note-profile',
}

export const permissionGridNoteSaleLead = {
    table: 'table-note-sale-lead',
    edit: 'edit-note-sale-lead',
    remove: 'remove-note-sale-lead',
}

export const permissionGridNoteSaleAccount = {
    table: 'table-note-sale-account',
    edit: 'edit-note-sale-account',
    remove: 'remove-note-sale-account',
}

export const permissionGridNoteOpportunity = {
    table: 'table-note-opportunity',
    edit: 'edit-note-opportunity',
    remove: 'remove-note-opportunity',
}

export const permissionEmailCampaignManagement = {
    toolbar: {
        toolbar: 'toolbar-email-campaign',
        add: 'add-email-campaign',
        import: 'import-email-campaign(turnoff)',
        export: 'export-email-campaign(turnoff)',
        customView: 'custom-view-email-campaign(turnoff)',
    },
    table: {
        table: 'table-email-campaign',
        edit: 'edit-email-campaign',
        remove: 'remove-email-campaign',
    }
}
export const permissionEmailCampaign = {
    toolbar: {
        toolbar: 'toolbar-campaign',
        add: 'add-campaign(turnoff)',
        import: 'import-campaign(turnoff)',
        export: 'export-campaign(turnoff)',
        customView: 'custom-view-campaign(turnoff)',
    },
    table: {
        table: 'table-campaign',
        edit: 'edit-campaign(turnoff)',
        remove: 'remove-campaign',
    }
};

export const permissionSelectEmailCampaign = {
    toolbar: {
        toolbar: 'toolbar-campaign',
        add: 'add-campaign(turnoff)',
        import: 'import-campaign(turnoff)',
        export: 'export-campaign(turnoff)',
        customView: 'custom-view-campaign(turnoff)',
    },
    table: {
        table: 'table-campaign',
        edit: 'edit-campaign(turnoff)',
        remove: 'remove-campaign(turnoff)',
    }
};
export const permissionUserNote = {
    toolbar: {
        toolbar: 'toolbar-user-note',
        add: 'add-user-note',
        import: 'import-user-note(turnoff)',
        export: 'export-user-note(turnoff)',
        customView: 'custom-view-user-note(turnoff)',
    },
    table: {
        table: 'table-user-note',
        edit: 'edit-user-note',
        remove: 'remove-user-note',
    }
}

export const permissionUserTab = {
    toolbar: {
        toolbar: 'toolbar-user-tab',
        add: 'add-user-tab',
        import: 'import-user-tab(turnoff)',
        export: 'export-user-tab',
        customView: 'custom-view-user-tab(turnoff)',
    },
    table: {
        table: 'table-user-tab',
        edit: 'edit-user-tab',
        remove: 'remove-user-tab',
    }
}
export const permissionUserTabHistory = {
    toolbar: {
        toolbar: 'toolbar-user-tab',
        add: 'add-user-tab(turnoff)',
        import: 'import-user-tab(turnoff)',
        export: 'export-user-tab(turnoff)',
        customView: 'custom-view-user-tab(turnoff)',
    },
    table: {
        table: 'table-user-tab',
        edit: 'edit-user-tab',
        remove: 'remove-user-tab(turnoff)',
    }
}
export const permissionRealtimeNotificationQueue = {
    toolbar: {
        toolbar: 'toolbar-notification-queue',
        add: 'add-notification-queue',
        import: 'import-notification-queue(turnoff)',
        export: 'export-notification-queue(turnoff)',
        customView: 'custom-view-notification-queue',
    },
    table: {
        table: 'table-notification-queue',
        edit: 'edit-notification-queue',
        remove: 'remove-notification-queue',
    }
}

export const permissionDynamicContent = {
    toolbar: {
        toolbar: 'toolbar-dynamic-content',
        add: 'add-dynamic-content',
        import: 'import-dynamic-content',
        export: 'export-dynamic-content',
        customView: 'custom-view-dynamic-content',
    },
    table: {
        table: 'table-dynamic-content',
        edit: 'edit-dynamic-content',
        remove: 'remove-dynamic-content',
    }
}

export const permissionEmailContactGroupReport = {
    toolbar: {
        toolbar: 'toolbar-campaign',
        add: 'add-campaign',
        import: 'import-campaign(turnoff)',
        export: 'export-campaign(turnoff)',
        customView: 'custom-view-campaign(turnoff)',
    },
    table: {
        table: 'table-campaign',
        edit: 'edit-campaign(turnoff)',
        remove: 'remove-campaign',
    }
};


export const permissionResellerTab = {
    add: 'add-reseller-account-tab', // hardcode at reseller tab in sale account detail.
    tab: {
        table: 'table-reseller-account-tab',
        edit: 'edit-reseller-account-tab',
        remove: 'remove-reseller-account-tab(turnoff)',
    }
}


export const permissionRelationshipTab = {
    add: 'add-relationship-account-tab', // hardcode at reseller tab in sale account detail.
    tab: {
        table: 'table-relationship-account-tab',
        edit: 'edit-relationship-account-tab',
        remove: 'remove-relationship-account-tab(turnoff)',
    }
}
export const permissionUserUpload = {
    toolbar: {
        toolbar: 'toolbar-user-upload',
        add: 'add-user-upload',
        import: 'import-user-upload(turnoff)',
        export: 'export-user-upload(turnoff)',
        customView: 'custom-view-user-upload',
    },
    table: {
        table: 'table-user-upload',
        edit: 'edit-user-upload',
        remove: 'remove-user-upload(turnoff)',
    }
};

export const permissionSaleAccountFile = {
    toolbar: {
        toolbar: 'toolbar-sale-account-file',
        add: 'add-sale-account-file',
        import: 'import-sale-account-file(turnoff)',
        export: 'export-sale-account-file(turnoff)',
        customView: 'custom-view-sale-account-file',
    },
    table: {
        table: 'table-sale-account-file',
        edit: 'edit-sale-account-file(turnoff)',
        remove: 'remove-sale-account-file(turnoff)',
    }
};

export const permissionSaleLeadFile = {
    toolbar: {
        toolbar: 'toolbar-sale-lead-file',
        add: 'add-sale-lead-file',
        import: 'import-sale-lead-file(turnoff)',
        export: 'export-sale-lead-file(turnoff)',
        customView: 'custom-view-sale-lead-file',
    },
    table: {
        table: 'table-sale-lead-file',
        edit: 'edit-sale-lead-file(turnoff)',
        remove: 'remove-sale-lead-file(turnoff)',
    }
};

export const permissionSaleOpportunityFile = {
    toolbar: {
        toolbar: 'toolbar-sale-opportunity-file',
        add: 'add-sale-opportunity-file',
        import: 'import-sale-opportunity-file(turnoff)',
        export: 'export-sale-opportunity-file(turnoff)',
        customView: 'custom-view-sale-opportunity-file',
    },
    table: {
        table: 'table-sale-opportunity-file',
        edit: 'edit-sale-opportunity-file(turnoff)',
        remove: 'remove-sale-opportunity-file(turnoff)',
    }
};
export const permissionProfileFile = {
    toolbar: {
        toolbar: 'toolbar-profile-file',
        add: 'add-profile-file',
        import: 'import-profile-file(turnoff)',
        export: 'export-profile-file(turnoff)',
        customView: 'custom-view-profile-file',
    },
    table: {
        table: 'table-profile-file',
        edit: 'edit-profile-file(turnoff)',
        remove: 'remove-profile-file(turnoff)',
    }
}

export const permissionSaleConvertHistory = {
    toolbar: {
        toolbar: 'toolbar-sale-convert-history',
        add: 'add-sale-convert-history(turnoff)',
        import: 'import-sale-convert-history(turnoff)',
        export: 'export-sale-convert-history(turnoff)',
        customView: 'custom-view-sale-convert-history(turnoff)',
    },
    table: {
        table: 'table-sale-convert-history',
        edit: 'edit-sale-convert-history(turnoff)',
        remove: 'remove-sale-convert-history(turnoff)',
    }
};
export const permissionContactMap = {
    toolbar: {
        toolbar: 'toolbar-dashboard-contact-map(turnoff)',
        add: 'add-dashboard-contact-map(turnoff)',
        import: 'import-dashboard-contact-map(turnoff)',
        export: 'export-dashboard-contact-map(turnoff)',
        customView: 'custom-view-dashboard-contact-map(turnoff)',
    },
    table: {
        table: 'table-dashboard-contact-map',
        remove: 'remove-dashboard-contact-map(turnoff)',
    }
};

export const permissionCustomView = {
    toolbar: {
        toolbar: 'toolbar-custom-view',
        add: 'add-custom-view(turnoff)',
        import: 'import-custom-view(turnoff)',
        export: 'export-custom-view(turnoff)',
        customView: 'custom-view-custom-view(turnoff)',
    },
    table: {
        table: 'table-custom-view',
        edit: 'edit-custom-view',
        remove: 'remove-custom-view',
    }
};

export const permissionTags = {
    toolbar: {
        toolbar: 'toolbar-tags',
        add: 'add-tags',
        import: 'import-tags',
        export: 'export-tags',
        customView: 'tags-tags',
    },
    table: {
        table: 'table-tags',
        edit: 'edit-tags',
        remove: 'remove-tags',
    }
};
export const permissionReminderHistory = {
    toolbar: {
        toolbar: 'toolbar-reminder-history',
        add: 'add-reminder-history(turnoff)',
        import: 'import-reminder-history(turnoff)',
        export: 'export-reminder-history',
        customView: 'custom-view-reminder-history',
    },
    table: {
        table: 'table-reminder-history',
        edit: 'edit-reminder-history(turnoff)',
        remove: 'remove-reminder-history',
    }
}

export const permissionUserToken = {
    toolbar: {
        toolbar: 'toolbar-user-token',
        add: 'add-user-token(turnoff)',
        import: 'import-user-token(turnoff)',
        export: 'export-user-token(turnoff)',
        customView: 'custom-view-user-token',
    },
    table: {
        table: 'table-user-token',
        edit: 'edit-user-token(turnoff)',
        remove: 'remove-user-token',
    }
};

export const permissionFrontPageData = {
    toolbar: {
        toolbar: 'toolbar-front-page-data',
        add: 'add-front-page-data(turnoff)',
        import: 'import-front-page-data(turnoff)',
        export: 'export-front-page-data(turnoff)',
        customView: 'custom-view-front-page-data',
    },
    table: {
        table: 'table-front-page-data',
        edit: 'edit-front-page-data',
        remove: 'remove-front-page-data',
    }
};

export const permissionScheduleGroup = {
    toolbar: {
        toolbar: 'toolbar-schedule-group',
        add: 'add-schedule-group',
        import: 'import-schedule-group(turnoff)',
        export: 'export-schedule-group',
        customView: 'custom-view-schedule-group',
    },
    table: {
        table: 'table-schedule-group',
        edit: 'edit-schedule-group',
        remove: 'remove-schedule-group',
    }
}
export const permissionPatientDashboard = {
    toolbar: {
        toolbar: 'toolbar-patient-dashboard',
        add: 'add-patient-dashboard(turnoff)',
        import: 'import-patient-dashboard(turnoff)',
        export: 'export-patient-dashboard(turnoff)',
        customView: 'custom-view-patient-dashboard',
    },
    table: {
        table: 'table-patient-dashboard(turnoff)',
        edit: 'edit-patient-dashboard(turnoff)',
        remove: 'remove-patient-dashboard(turnoff)',
    }
}

export const permissionVitalsHistoryTab = {
    toolbar: {
        toolbar: 'toolbar-patient-vital-history-tab',
        add: 'add-patient-vital-history-tab(turnoff)',
        import: 'import-patient-vital-history-tab(turnoff)',
        export: 'export-patient-vital-history-tab(turnoff)',
        customView: 'custom-view-patient-vital-history-tab(turnoff)',
    },
    table: {
        table: 'table-patient-vital-history-tab',
        edit: 'edit-patient-vital-history-tab(turnoff)',
        remove: 'remove-patient-vital-history-tab(turnoff)',
    }
}
export const subPatientScheduleGroupTable = {
    toolbar: {
        toolbar: 'toolbar-schedule-group',
        add: 'add-schedule-group(turnoff)',
        import: 'import-schedule-group(turnoff)',
        export: 'export-schedule-group(turnoff)',
        customView: 'custom-view-schedule-group(turnoff)',
    },
    table: {
        table: 'table-schedule-group',
        edit: 'edit-schedule-group',
        remove: 'remove-schedule-group(turnoff)',
    }
}
export const saleAccountScheduleGroup = {
    toolbar: {
        toolbar: 'toolbar-patient-schedule-group-tab',
        add: 'add-patient-schedule-group-tab',
        import: 'import-patient-schedule-group-tab',
        export: 'export-patient-schedule-group-tab',
        customView: 'custom-view-patient-schedule-group-tab',
    },
    table: {
        table: 'table-patient-schedule-group-tab',
        edit: 'edit-patient-schedule-group-tab(turnoff)',
        remove: 'remove-patient-schedule-group-tab',
    }
}
export const permissionSMSPatientMonitoring = {
  toolbar: {
      toolbar: 'toolbar-sms-patient-monitoring',
      add: 'add-sms-patient-monitoring(turnoff)',
      import: 'import-sms-patient-monitoring(turnoff)',
      export: 'export-sms-patient-monitoring(turnoff)',
      customView: 'custom-view-sms-patient-monitoring(turnoff)',
  },
  table: {
      table: 'table-sms-patient-monitoring',
      edit: 'edit-sms-patient-monitoring',
      remove: 'remove-sms-patient-monitoring(turnoff)',
  }
}
export const permissionPatientScheduleHistoryTab = {
  toolbar: {
      toolbar: 'toolbar-patient-schedule-history-tab',
      add: 'add-patient-schedule-history-tab(turnoff)',
      import: 'import-patient-schedule-history-tab(turnoff)',
      export: 'export-patient-schedule-history-tab(turnoff)',
      customView: 'custom-view-patient-schedule-history-tab(turnoff)',
  },
  table: {
      table: 'table-patient-schedule-history-tab',
      edit: 'edit-patient-schedule-history-tab(turnoff)',
      remove: 'remove-patient-schedule-history-tab(turnoff)',
  }
}

export const permissionMessageProvider = {
    toolbar: {
        toolbar: 'toolbar-message-provider',
        add: 'add-message-provider',
        import: 'import-message-provider(turnoff)',
        export: 'export-message-provider(turnoff)',
        customView: 'custom-message-provider(turnoff)',
    },
    table: {
        table: 'table-message-provider',
        edit: 'edit-message-provider',
        remove: 'remove-message-provider',
    }
  }
export const permissionLicenseReportDetail = {
    toolbar: {
        toolbar: 'toolbar-license-report-detail',
        add: 'add-license-report-detail(turnoff)',
        import: 'import-license-report-detail(turnoff)',
        export: 'export-license-report-detail(turnoff)',
        customView: 'custom-license-report-detail(turnoff)',
    },
    table: {
        table: 'table-license-report-detail',
        edit: 'edit-license-report-detail(turnoff)',
        remove: 'remove-license-report-detail(turnoff)',
    }
}