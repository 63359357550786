import { DateFormatPipe } from './../../pipes/date-time-format.pipe';
import { Component, EventEmitter, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { FileValidator } from 'ngx-material-file-input';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import { Helper } from '../../utility/Helper';

@Component({
  selector: 'app-import-modal',
  templateUrl: './import-modal.component.html',
  styleUrls: ['./import-modal.component.scss']
})
export class ImportModalComponent implements OnInit {
  @ViewChild('importRequired', { static: true }) importRequired: TemplateRef<any>;
  @ViewChild('headerFromFile', { static: true }) headerFromFile: TemplateRef<any>;
  @ViewChild('reviewInfo', { static: true }) reviewInfo: TemplateRef<any>;

  @ViewChild('stepper') private stepper: MatStepper;
  @ViewChild(DatatableComponent) table: DatatableComponent;

  readonly maxSize = environment.maxSizeUpload;
  isLoading = false;
  formDoc: FormGroup;
  isComplete = false;
  columns = [];
  systemProperty: string[];
  templateFile: string;
  rows = new Array<any>();
  columnsFile = new Array<any>();
  ColumnMode = ColumnMode;
  onImport = new EventEmitter();

  totalRow: number;
  totalSuccess: number;
  totalError: number;
  fileError: any = null;
  systemError = false;
  constructor(private frmBuilder: FormBuilder, public dialModalRef: MatDialogRef<ImportModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, datePipe: DateFormatPipe) { }

  ngOnInit() {
    this.columns = [
      {
        name: 'System property',
        prop: 'systemPropertyName',
        cellTemplate: this.importRequired,
        width: 176,
        resizeable: false,
        canAutoResize: false,
      },
      {
        name: 'Column Header From File',
        prop: 'columnFile',
        cellTemplate: this.headerFromFile,
        width: 176,
        resizeable: false,
        canAutoResize: false,
      },
      {
        name: 'Preview Info',
        prop: 'reviewInfo',
        cellTemplate: this.reviewInfo,
        width: 176,
        resizeable: false,
        canAutoResize: false,
      },

    ];
    this.systemProperty = this.data?.systemProperty;
    this.templateFile = this.data?.templateFile;
    if (this.systemProperty && this.systemProperty.length > 0) {
      this.systemProperty.forEach((x: any) => {
        this.rows.push({
          "systemPropertyName": x.name,
          "systemProperty": x.prop,
          "importRequired": x.importRequired,
          "columnFile": - 1,
          "columnHeader": null,
          "reviewInfo": null
        })
      })
    }
    this.dialModalRef.updatePosition({ right: '0' });
    this.dialModalRef.updateSize('700px', '100%')
    this.formDoc = this.frmBuilder.group({
      requiredfile: [
        undefined,
        [Validators.required, FileValidator.maxContentSize(this.maxSize)]
      ]
    });
  }
  closeDialog() {
    this.dialModalRef.close();
  }
  onStepChange(event) {
    if (event.selectedIndex == 1) {
      this.totalRow = 0;
      this.totalSuccess = 0;
      this.totalError = 0;
      this.fileError = null;
      this.systemError = false;
      this.isComplete = false;
      this.columnsFile = [];
      this.rows.forEach(x => {
        x.columnFile = -1;
        x.reviewInfo = null;
      });
      var reader: FileReader = new FileReader();
      reader.readAsBinaryString(this.formDoc.controls["requiredfile"].value.files[0]);
      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary', sheetRows: 2 });

        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        // ws['!fullref'] = "A1:A3"
        /* save data */

        if (ws) {
          for (var i in ws) {
            if (ws[i].w && ws[i].v) {
              if (Helper.isDateString(ws[i].w)) {
                ws[i].v = ws[i].w;
              }
            }
          }
        }
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 }); // to get 2d array pass 2nd parameter as object {header: 1}
        if (data != null && data.length > 0) {
          let tempColumn = data[0] as [];
          let tempDataColumn = data[1] as [];
          for (let i = 0; i < tempColumn.length; i++) {
            let tempData: any = {
              value: i + 1,
              name: tempColumn[i],
              data: tempDataColumn != null ? tempDataColumn[i] : null,
            }
            // 2022-09-07 tienlm mod start: Improve import mapping
            let formattedName = tempData.name.replace(' ', '').toLowerCase();
            //fill data
            let row = this.rows.find(x =>
              x.systemPropertyName.replace(' ', '').toLowerCase() == formattedName ||
              x.systemPropertyName.toLowerCase() == formattedName ||
              x.systemProperty.toLowerCase() == formattedName
            );
            // 2022-09-07 tienlm mod end
            if (row != null) {
              tempData.selected = true;
              row.columnFile = tempData.value;
              row.columnHeader = tempData.name;
              row.reviewInfo = tempData.data;
              this.rows = [...this.rows];
            }
            setTimeout(() => {
              this.table.recalculate();
              this.table['cd'].markForCheck();
              document.body.style.width = 'auto';
            }, 0);
            this.columnsFile.push(tempData);
          }
        } else {
          //revent file
        }
      };
      setTimeout(() => {
        this.table.recalculate();
        this.table['cd'].markForCheck();
        document.body.style.width = 'auto';
      }, 0);
    }
  }
  finishImport(importResult) {
    this.isComplete = true;
    this.stepper.selected.completed = true;
    this.stepper.next();
    this.isLoading = !this.isLoading;
    if (importResult) {
      this.totalError = importResult.totalError;
      this.totalRow = importResult.totalRow;
      this.totalSuccess = importResult.totalSuccess;
      this.fileError = importResult.file;
    } else {
      this.systemError = true;
    }
  }
  onChangeColumn(event, oldValue, rowIndex) {
    if (event.value > -1) {
      let column = this.columnsFile.find(x => x.value == event.value);
      if (column) {
        column.selected = true;
        this.rows[rowIndex].reviewInfo = column.data;
        this.rows[rowIndex].columnHeader = column.name;
      }
    } else {
      this.rows[rowIndex].reviewInfo = null;
    }
    if (oldValue > -1) {
      this.columnsFile.find(x => x.value == oldValue).selected = false;
    }
    this.rows[rowIndex].columnFile = event.value;
    this.rows = [...this.rows];
  }

  importFile() {
    this.isLoading = !this.isLoading;
    const formData = new FormData();
    formData.append('file', this.formDoc.controls["requiredfile"].value.files[0]);
    formData.append('mapping', JSON.stringify(this.rows.map(x => ({
      index: x.columnFile,
      prop: x.systemProperty,
      name: x.systemPropertyName,
      columnHeader: x.columnHeader,
      inputValue: x.columnFile == -2 ? x.reviewInfo?.toString() : '',
    }))))
    this.onImport.emit(formData);
  }

  downloadFileError() {
    Helper.downloadFile(this.fileError);
  }

  inputProperty(rowIndex: any, value: string) {
    if (rowIndex > -1 && value) this.rows[rowIndex].reviewInfo = value;
    this.rows = [...this.rows];
  }
}
